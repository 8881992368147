<template lang="pug">
  article.editions.px-12.pb-px.pt-72.md-pt-144.md-px-24.lg-px-40.bg-white.min-h-screen.flex.flex-col(v-on:after-enter="$emit('afterEnter')")
    template(v-if="doc")
      .colc-grid.flex.flex-1(ref="egrid")
        //- Columns
        .colc-grid-col.colc-grid-col--1.px-12.md-px-24.flex-grow.lg-px-40
        .colc-grid-col.colc-grid-col--2.px-12.md-px-24.flex-grow.lg-px-40.hidden.md-block
        .colc-grid-col.colc-grid-col--3.px-12.md-px-24.flex-grow.lg-px-40.hidden.lg-block
        //- items
        router-link.colc-grid-item.block.mb-72(v-for="(prj, i) in doc.data.body", :key="`${i}${prj.primary.product.uid}`", :to="{name: 'product', params: {uid: prj.primary.product.uid}}")
          article(v-if="prj.primary")
            //- Img
            figure.mb-27(v-if="prj.primary.image")
              resp-img(:bg="false", :lazy="i > 6", :lngth="imgL", :src="prj.primary.image.url", :alt="prj.primary.image.alt || prj.primary.product.uid", v-if="imgL")
            //- Title
            h3.text-16.text-center.mb-12.italic.font-medium {{ txt(prj.primary.product.data.title) }}
            //- Description
            prismic-rich-text.text-11.md-text-14(:field="prj.primary.product.data.description")

      //- footer
      shop-footer-links
</template>

<script>
import Vue from 'vue'
import Colcade from 'colcade'
import { mapState } from 'vuex'
import RespImg from '@/components/RespImg'
import ShopFooterLinks from '@/components/ShopFooterLinks'
export default {
  name: 'Editions',
  data () {
    return {
      txt: this.$prismic.richTextAsPlain
    }
  },
  computed: {
    ...mapState(['winW']),
    doc () {
      return this.$store.state.singles['editions']
    },
    imgL () {
      const w = this.winW
      const bkpts = Vue.config.bkpts
      return w >= bkpts.md ? parseInt(w * 0.33) // 3 col
        : w >= bkpts.sm ? parseInt(w * 0.5) // 2 col
          : w
    }
  },
  methods: {
    initGrid () {
      this.colc = new Colcade(this.$refs.egrid, {
        columns: '.colc-grid-col',
        items: '.colc-grid-item'
      })
    }
  },
  async mounted () {
    // fetch content
    this.$Progress.start()
    await this.$store.dispatch('getSingle', 'editions')
    this.$Progress.finish()

    // init grid after content fetched
    this.initGrid()
  },
  components: { RespImg, ShopFooterLinks }
}
</script>

<style>

</style>
